<template>
  <div class="pageContainer flexCloumn">
    <el-row class="searchBar flexCloumn" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1 w_100" style="flex-wrap:wrap;">

        <!--        <tchtree v-model="searchForm.class_ids" @change="getlist" :isAll="1"></tchtree>-->
        <el-input v-model="searchForm.stu_name" size="small" placeholder="活动创建人"
          style="max-width: 200px; margin-right: 10px;" />
        <el-input v-model="searchForm.activity_name" size="small" placeholder="活动名称"
          style="max-width: 200px; margin-right: 10px; margin-left: 10px" />
        <el-date-picker v-model="searchForm.date_str" value-format="yyyy-MM" type="month" size="small"
          style="width: 150px" placeholder="活动时间">
        </el-date-picker>
        <el-select v-model="searchForm.activity_status" placeholder="请选择审核状态" size="small"
          style="max-width: 150px; margin-right: 10px; margin-left: 10px">
          <el-option label="待审核" value="0"></el-option>
          <el-option label="未通过" value="-1"></el-option>
          <el-option label="通过" value="1"></el-option>
        </el-select>
        <el-button class="ml10" size="small" icon="el-icon-search" @click="onSearch" type="primary">查询</el-button>
        <el-button size="small" icon="el-icon-refresh" @click="onReset" type="warning">重置</el-button>
      </div>

    </el-row>
    <div class="dividerBar"></div>



    <el-table :data="dataList" height="100%" size="small" stripe border style="width:fit-content;width:100%;">
      <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
      <el-table-column prop="activity_name" label="活动名称" min-width="200"></el-table-column>
      <el-table-column prop="address" label="活动地址" min-width="120"></el-table-column>
      <el-table-column prop="total_people" label="计划人数" width="85"></el-table-column>
      <el-table-column prop="activity_content" label="活动内容" min-width="300" show-overflow-tooltip></el-table-column>
      <el-table-column prop="sta_activity_date" label="活动开始时间" width="185"></el-table-column>
      <el-table-column prop="sta_register_date" label="报名开始时间" width="185"></el-table-column>
      <el-table-column prop="end_register_date" label="报名结束时间" width="185"></el-table-column>
      <el-table-column prop="stu_name" label="活动创建人" width="105"></el-table-column>
      <el-table-column prop="phone" label="联系电话" min-width="135"></el-table-column>
      <el-table-column fixed="right" prop="activity_status" label="审核状态" min-width="120">
        <template slot-scope="scope">
          <span style="color: #67c23a;" v-if="scope.row.activity_status == 1">通过</span>
          <span style="color: #ff5500;" v-if="scope.row.activity_status == -1">未通过</span>
          <span style="color: #409eff;" v-if="scope.row.activity_status == 0">待审核</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="审核人" min-width="120"></el-table-column>
      <el-table-column prop="remark" label="评语" min-width="120"></el-table-column>
      <el-table-column fixed="right" label="操作" width="80">
        <template slot-scope="scope">
          <div class="flexStart">
            <el-button v-if="scope.row.activity_status==0" type="text" size="small" @click="review(scope.row)"
              style="margin: 0 !important">审核</el-button>
            <el-button v-if="scope.row.activity_status==-1" type="text" size="small" @click="review(scope.row)"
              style="margin: 0 !important">重新审核</el-button>
               <el-button type="text" size="small" @click="del(scope.row)"
              style="margin: 0 !important;color:#ff5500;">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
      :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
      :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>
    <el-dialog title="活动审核" :visible.sync="dialogVisible" width="30%">
      <el-form ref="form" :model="ruleForm" label-width="80px">

        <el-form-item label="审核状态">
          <el-select v-model="ruleForm.activity_status" placeholder="审核状态" @change="$forceUpdate()" size="small">
            <el-option label="通过" :value="1">通过</el-option>
            <el-option label="不通过" :value="-1">不通过</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核理由">
          <el-input size="small" type="textarea" v-model="ruleForm.remark" placeholder="审核理由"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import tchtree from "../../com/tchTree.vue";
export default {
  components: { tchtree },
  data() {
    return {

      activeName: "td",
      dataList: [],
      showSP: false,
      spword: "",
      showPrint: false,
      visible: false,
      detailForm: {},
      dialogVisible: false,
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      searchForm: {},
      ruleForm:{},
    };
  },
  mounted() {
    console.log(sessionStorage.getItem("auth"));
    this.getlist();
  },
  methods: {
    onView(row) {
      this.visible = true
      this.detailForm = row
    },
    getYears() {
      return new Promise((resolve, reject) => {
        this.$http.post("/api/njwx_stu_years").then(res => {
          this.YearList = res.data
          this.searchForm.year = res.data[0].gyear
          resolve()
        }).catch(err => {
          resolve()

        })
      })
    },
    getlist() {
      this.$http
        .post("/api/sys_activity_list", {
          page: this.page.current_page,
          pagesize: this.page.per_page,
          stu_name: this.searchForm.stu_name,
          activity_name: this.searchForm.activity_name,
          activity_status: this.searchForm.activity_status,
          date_str:this.searchForm.date_str,
        })
        .then((res) => {
          this.dataList = res.data.data;
          this.page = res.data.page
        });
    },
    handlePageChange(page) {
      this.page.current_page = page
      this.getlist()
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getlist()
    },
    changeTab() {
      this.getlist();
    },
    onSearch() {
      this.getlist();
    },
    onReset() {
      this.searchForm = {};
      this.getlist();
    },
    review(e) {
      this.ruleForm.id = e.id;
      this.ruleForm.activity_status = undefined
      this.dialogVisible = true;
    },
    del(row) {
      this.$confirm('此操作不可恢复，是否确认删除？', {
        type: 'warning'
      }).then(() => {
        this.$http
          .post("/api/sys_activity_delete", { id: row.id })
          .then((res) => {
            this.$message.success('删除成功')
            this.getlist();
          });
      })

      
    },
    onSubmit() {
      this.$http
        .post("/api/check_activity", this.ruleForm)
        .then((res) => {
          this.dialogVisible = false;
          this.getlist();
        });
    },
  },
};
</script>
